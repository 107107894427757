var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "slider-item",
      class: [_vm.classSlide, { "slider-item-active": _vm.getActive }],
    },
    [_vm.$slots.default ? _vm._t("default") : _vm._e()],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }