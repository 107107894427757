<template>
    <div
        ref="slider"
        class="slider"
        :class="{
            'slider-partial': partial,
            'slider-start': itemFirstInView,
            'slider-end': itemLastInView,
            'slider-controls-on-hover': controlsOnHover,
        }"
    >
        <base-slider-inner
            ref="sliderInner"
            v-model="internalActive"
            :class-inner="classInner"
            @updateItems="items = $event"
        >
            <base-slider-slide
                v-for="(_, index) in sliderSlots"
                :key="index"
                :class-slide="classSlide"
            >
                <slot :name="index" />
            </base-slider-slide>
        </base-slider-inner>

        <template v-if="hasControls">
            <button
                type="button"
                class="slider-control slider-control-prev"
                :disabled="controls !== 'screen' ? !hasBtn.itemPrev : !hasBtn.screenPrev"
                :hidden="controls !== 'screen' ? !hasBtn.itemPrev : !hasBtn.screenPrev"
                @click="controls !== 'screen' ? goToItemPrev() : goToScreenPrev()"
            >
                <base-icon
                    class="icon-lg"
                    name="arrow-left"
                    aria-hidden="true"
                />
                <span class="visually-hidden">&lt;</span>
            </button>

            <button
                type="button"
                class="slider-control slider-control-next"
                :disabled="controls !== 'screen' ? !hasBtn.itemNext : !hasBtn.screenNext"
                :hidden="controls !== 'screen' ? !hasBtn.itemNext : !hasBtn.screenNext"
                @click="controls !== 'screen' ? goToItemNext() : goToScreenNext()"
            >
                <base-icon
                    class="icon-lg"
                    name="arrow-right"
                    aria-hidden="true"
                />
                <span class="visually-hidden">&gt;</span>
            </button>
        </template>

        <ul
            v-if="indicators && Object.keys(items).length > 1"
            ref="sliderIndicators"
            class="slider-indicators"
            :class="{ 'slider-indicators-bullets' : indicators.style === 'bullets' }"
        >
            <template v-if="indicators.type !== 'screen'">
                <li
                    v-for="(item, index) in items"
                    :key="`items-${index}`"
                    :class="{ 'active': item.inView }"
                >
                    <a
                        :href="`#item-${index}`"
                        @click.prevent="goTo(index)"
                    >
                        {{ index }}
                    </a>
                </li>
            </template>
            <template v-else>
                <li
                    v-for="(_, index) in screensLength"
                    :key="`screens-${index}`"
                    :class="{ 'active': screensInView.has(index) }"
                >
                    <button
                        type="button"
                        @click="goTo(index, 'screen')"
                    >
                        {{ index }}
                    </button>
                </li>
            </template>
        </ul>

        <slot name="default" />
    </div>
</template>

<script>
import BaseSliderInner from './BaseSliderInner.vue';
import BaseSliderSlide from './BaseSliderSlide.vue';

export default {
    name: 'BaseSlider',

    components: {
        BaseSliderInner,
        BaseSliderSlide,
    },

    model: {
        prop: 'active',
        event: 'active:change',
    },

    props: {
        active: {
            type: Number,
            default: null,
        },
        controls: {
            type: [Boolean, String],
            default: true,
        },
        controlsOnHover: {
            type: Boolean,
            default: false,
        },
        infinite: {
            type: Boolean,
            default: true,
        },
        indicators: {
            type: [Boolean, Object],
            default: false,
        },
        interval: {
            type: [Boolean, Number],
            default: false,
        },
        partial: {
            type: Boolean,
            default: false,
        },
        classInner: {
            type: [String, Array],
            default: 'row',
        },
        classSlide: {
            type: String,
            default: 'col-auto',
        },
    },

    data: () => ({
        items: {},
        intervalIntervalId: null,
        intervalObserver: null,
        internalActive: null,
    }),

    computed: {
        itemsLength() {
            return Object.keys(this.items).length;
        },
        itemsInView() {
            const items = Object.values(this.items)
                .filter((item) => item.inView);

            return items || [];
        },
        itemInViewFirst() {
            return this.itemsInView[0];
        },
        itemInViewLast() {
            return this.itemsInView[this.itemsInView.length - 1];
        },
        itemFirstInView() {
            return this.itemInViewFirst?.index === 0;
        },
        itemLastInView() {
            return this.itemInViewLast?.index + 1 === this.itemsLength;
        },
        screensInView() {
            const screens = new Set();
            this.itemsInView.forEach((item) => {
                screens.add(item.screen);
            });

            return screens;
        },
        screenInViewFirst() {
            return this.screensInView.values().next().value;
        },
        screensLength() {
            const [lastItem] = Object.values(this.items).slice(-1);

            if (!lastItem) return null;

            return lastItem.screen + 1;
        },
        hasBtn() {
            const output = {
                itemPrev: null,
                itemNext: null,
                screenPrev: null,
                screenNext: null,
            };

            if (this.infinite) {
                output.itemPrev = true;
                output.itemNext = true;
                output.screenPrev = true;
                output.screenNext = true;
            } else {
                output.itemPrev = !this.itemFirstInView;
                output.itemNext = !this.itemLastInView;
                output.screenPrev = this.screenInViewFirst !== 0;
                output.screenNext = this.screenInViewFirst + 1 !== this.screensLength;
            }

            return output;
        },
        hasControls() {
            const itemsNotInView = this.itemsInView.length > 0
                && this.itemsInView.length < this.itemsLength;
            return this.controls && itemsNotInView;
        },
        sliderSlots() {
            return Object.keys(this.$slots).filter((slot) => slot !== 'default');
        },
    },

    watch: {
        active(newValue) {
            this.internalActive = newValue;
        },
    },

    mounted() {
        const {
            slider,
            sliderInner: { $el: sliderInner },
        } = this.$refs;

        this.goTo(this.active, 'item', this.items, 'auto');
        if (this.interval) this.attachInterval(slider, sliderInner);
        if (typeof this.active === 'number') this.internalActive = this.active;
    },

    methods: {
        goToWantedEntityValidator(to, entityIndex, entityLength) {
            let wantedEntityIndex = entityIndex;

            const { sliderInner: { $el: sliderInner } } = this.$refs;
            const {
                scrollLeft: sliderScrollLeft,
                scrollWidth: sliderScrollWidth,
                offsetWidth: sliderWidth,
            } = sliderInner;

            let sliderChangedDirection = false;
            const sliderScrollStart = Math.abs(sliderScrollLeft);
            const sliderScrollEnd = sliderScrollStart + sliderWidth;
            const scrollStartFinished = sliderScrollStart === 0 && to === 'prev';
            const scrollEndFinished = sliderScrollEnd === sliderScrollWidth && to === 'next';
            const wantedEntityIndexStartFinished = wantedEntityIndex < 0;
            const wantedEntityIndexEndFinished = wantedEntityIndex + 1 > entityLength;

            if (scrollStartFinished || wantedEntityIndexStartFinished) {
                wantedEntityIndex = entityLength - 1;
                sliderChangedDirection = true;
            } else if (scrollEndFinished || wantedEntityIndexEndFinished) {
                wantedEntityIndex = 0;
                sliderChangedDirection = true;
            }

            if (!this.infinite && sliderChangedDirection) {
                return false;
            }

            return wantedEntityIndex;
        },
        goToWantedEntity(to, entity, items) {
            let wantedEntityIndex = null;

            let direction = null;
            if (to === 'prev') direction = -1;
            else if (to === 'next') direction = +1;

            let entityIndex = null;
            let entityLength = null;
            if (entity === 'item') {
                entityIndex = this.itemInViewFirst.index;
                entityLength = this.itemsLength;
            } else if (entity === 'screen') {
                entityIndex = this.screenInViewFirst;
                entityLength = this.screensLength;
                const itemFromScreenNotInView = Object.values(items)
                    .find((item) => item.screen === entityIndex && !item.inView);

                if (to === 'prev' && itemFromScreenNotInView) {
                    direction = 0;
                }
            }

            wantedEntityIndex = entityIndex + direction;

            return this.goToWantedEntityValidator(to, wantedEntityIndex, entityLength);
        },
        goTo(to, entity = 'item', items = this.items, behavior = 'smooth') {
            let wantedEntityIndex = to;

            if (['prev', 'next'].includes(to)) {
                wantedEntityIndex = this.goToWantedEntity(to, entity, items);
            }

            if (!wantedEntityIndex && wantedEntityIndex !== 0) return false;

            let wantedItem = null;
            if (entity === 'item') wantedItem = items[wantedEntityIndex];
            else if (entity === 'screen') wantedItem = Object.values(items).find((item) => item.screen === wantedEntityIndex);
            const { scrollTo } = wantedItem;

            const { sliderInner: { $el: sliderInner } } = this.$refs;

            if (scrollTo === sliderInner.scrollLeft) return false;

            sliderInner.scrollTo({
                left: scrollTo,
                behavior,
            });

            this.$emit('active:change', wantedItem.index);
            this.internalActive = wantedItem.index;

            if (this.indicators) this.indicatorsAlignActive(wantedItem.index);

            return wantedItem.index;
        },
        goToItemPrev() {
            return this.goTo('prev');
        },
        goToItemNext() {
            return this.goTo('next');
        },
        goToScreenPrev() {
            return this.goTo('prev', 'screen');
        },
        goToScreenNext() {
            return this.goTo('next', 'screen');
        },

        attachInterval(slider, sliderInner) {
            const onIntersection = (entries) => {
                this.clearInterval();

                const { isIntersecting } = entries[0];

                if (!isIntersecting) return;

                this.startInterval();
            };

            this.intervalObserver = new IntersectionObserver(
                onIntersection,
                {
                    threshold: 0.5,
                    thresholds: [0, 0.5, 1],
                },
            );

            this.intervalObserver.observe(sliderInner);
            slider.addEventListener('mouseover', this.clearInterval);
            slider.addEventListener('mouseout', this.startInterval);
        },
        detachInterval(
            slider = this.$refs.slider,
            sliderInner = this.$refs.sliderInner.$el,
        ) {
            this.clearInterval();
            this.intervalObserver?.unobserve(sliderInner);
            slider.removeEventListener('mouseover', this.clearInterval);
            slider.removeEventListener('mouseout', this.startInterval);
        },
        startInterval() {
            const getInterval = typeof this.interval === 'number' ? this.interval : 5000;

            clearInterval(this.intervalIntervalId);
            this.intervalIntervalId = setInterval(() => {
                const itemIndex = this.goToItemNext();

                if (itemIndex === false) this.detachInterval();
            }, getInterval);
        },
        clearInterval() {
            clearInterval(this.intervalIntervalId);
        },
        indicatorsAlignActive(index) {
            const getLeftPosition = (target) => {
                if (this.indicators.alignActive === 'start') return target.offsetLeft;
                if (this.indicators.alignActive === 'end') return target.offsetLeft - target.parentNode.clientWidth + 20;
                if (target.offsetLeft < target.parentNode.clientWidth / 2) return null;

                return target.offsetLeft - (target.parentNode.clientWidth / 2);
            };

            const { sliderIndicators } = this.$refs;
            const activeIndicatorItem = sliderIndicators.children[index];

            sliderIndicators.scrollTo({
                left: getLeftPosition(activeIndicatorItem),
                behavior: 'smooth',
            });
        },
    },
};
</script>

<style lang="scss">
@import "./../scss/05-components/base-slider";
</style>
