var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "slider",
      staticClass: "slider",
      class: {
        "slider-partial": _vm.partial,
        "slider-start": _vm.itemFirstInView,
        "slider-end": _vm.itemLastInView,
        "slider-controls-on-hover": _vm.controlsOnHover,
      },
    },
    [
      _c(
        "base-slider-inner",
        {
          ref: "sliderInner",
          attrs: { "class-inner": _vm.classInner },
          on: {
            updateItems: function ($event) {
              _vm.items = $event
            },
          },
          model: {
            value: _vm.internalActive,
            callback: function ($$v) {
              _vm.internalActive = $$v
            },
            expression: "internalActive",
          },
        },
        _vm._l(_vm.sliderSlots, function (_, index) {
          return _c(
            "base-slider-slide",
            { key: index, attrs: { "class-slide": _vm.classSlide } },
            [_vm._t(index)],
            2
          )
        }),
        1
      ),
      _vm._v(" "),
      _vm.hasControls
        ? [
            _c(
              "button",
              {
                staticClass: "slider-control slider-control-prev",
                attrs: {
                  type: "button",
                  disabled:
                    _vm.controls !== "screen"
                      ? !_vm.hasBtn.itemPrev
                      : !_vm.hasBtn.screenPrev,
                  hidden:
                    _vm.controls !== "screen"
                      ? !_vm.hasBtn.itemPrev
                      : !_vm.hasBtn.screenPrev,
                },
                on: {
                  click: function ($event) {
                    _vm.controls !== "screen"
                      ? _vm.goToItemPrev()
                      : _vm.goToScreenPrev()
                  },
                },
              },
              [
                _c("base-icon", {
                  staticClass: "icon-lg",
                  attrs: { name: "arrow-left", "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "visually-hidden" }, [_vm._v("<")]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "slider-control slider-control-next",
                attrs: {
                  type: "button",
                  disabled:
                    _vm.controls !== "screen"
                      ? !_vm.hasBtn.itemNext
                      : !_vm.hasBtn.screenNext,
                  hidden:
                    _vm.controls !== "screen"
                      ? !_vm.hasBtn.itemNext
                      : !_vm.hasBtn.screenNext,
                },
                on: {
                  click: function ($event) {
                    _vm.controls !== "screen"
                      ? _vm.goToItemNext()
                      : _vm.goToScreenNext()
                  },
                },
              },
              [
                _c("base-icon", {
                  staticClass: "icon-lg",
                  attrs: { name: "arrow-right", "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "visually-hidden" }, [_vm._v(">")]),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.indicators && Object.keys(_vm.items).length > 1
        ? _c(
            "ul",
            {
              ref: "sliderIndicators",
              staticClass: "slider-indicators",
              class: {
                "slider-indicators-bullets": _vm.indicators.style === "bullets",
              },
            },
            [
              _vm.indicators.type !== "screen"
                ? _vm._l(_vm.items, function (item, index) {
                    return _c(
                      "li",
                      { key: `items-${index}`, class: { active: item.inView } },
                      [
                        _c(
                          "a",
                          {
                            attrs: { href: `#item-${index}` },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.goTo(index)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(index) +
                                "\n                "
                            ),
                          ]
                        ),
                      ]
                    )
                  })
                : _vm._l(_vm.screensLength, function (_, index) {
                    return _c(
                      "li",
                      {
                        key: `screens-${index}`,
                        class: { active: _vm.screensInView.has(index) },
                      },
                      [
                        _c(
                          "button",
                          {
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.goTo(index, "screen")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(index) +
                                "\n                "
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }